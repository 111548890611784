import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import moment from "moment";

function FlutterCallback() {

    const actionInit = 'init';
    const actionLogin = 'login';
    const actionLoginResponse = 'loginResponse';
    const actionLogout = 'logout';
    const actionRefresh = 'refresh';
    const actionCloseWeb = 'closeweb';
    const actionRequestNotificationPermission = 'requestNotificationPermission';
    const actionNotificationPermissionResponse = 'notificationPermissionResponse';
    const actionBack = 'back';

    const [messageFromFlutter, setMessageFromFlutter] = useState('No message received');
    const [logs, setLogs] = useState([]);
    const [mounted, setMounted] = useState(false);

    // 새로운 로그를 추가하는 함수
    const addLog = (newLog) => {
        setLogs((prevLogs) => [...prevLogs, '[' + moment().format('HH:mm:ss.SSS') + '] ' + newLog]);

        console.log(newLog);
    };

    const start = () => {
        if(mounted) {
            return;
        }

        setMounted(true);

        // 로그 시작
        addLog('Start logging...');
        addLog('');

        addLog('컴포넌트 mounted');

        window.handleMessageFromFlutter = handleMessageFromFlutter; // 전역 객체에 함수 등록

        // notify flutter of page loading is done.
        init();
    }

    // useEffect 훅을 사용하여 함수 컴포넌트의 함수를 전역으로 노출
    useEffect(() => {

        setTimeout(() => {
            start();
        }, 1000);

        return () => {
            addLog('컴포넌트 unmounted');
        };
    }, []);

    const onLogin = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);

        loginResponse();
    }

    const onNotificationPermissionResponse = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);
    }

    const onBack = (data) => {
        const strData = JSON.stringify(data);
        addLog(strData);

        // console.log('back is sent');
    }

    // Flutter --> React.js 로 함수 호출
    const handleMessageFromFlutter = (message) => {

        // jsonMessage = {type: "login", data: "this is a fcm token"}
        const jsonMessage = JSON.stringify(message);

        addLog('Flutter 에서 받은 메시지 : ' + jsonMessage);

        // console.log(jsonMessage);

        setMessageFromFlutter(jsonMessage);

        const data = message.data;
        const action = message.action;

        switch (action) {
            case actionInit:
                onLogin(data);
                break;
            case actionNotificationPermissionResponse:
                onNotificationPermissionResponse(data);
                break;
            case actionBack:
                onBack(data);
                break;
            default:
                break;
        }
    }

    const init = () => {
        // 객체 생성
        const messageObject = {
            action: actionInit,
            platfom: 'web',
            data: {
                status: true
            }
        };

        addLog('init 함수 호출');

        sendMessageToFlutter(messageObject);
    }

    const loginResponse = () => {
        const messageObject = {
            action: actionLoginResponse,
            os: "web",  // 또는 "app"
            data: {
                status: true
            }
        };

        addLog('loginResponse 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const requestNotificationPermission = () => {
        const messageObject = {
            action: actionRequestNotificationPermission,
            os: "web",  // 또는 "app"
            data: {
                // status: true
            }
        };

        addLog('requestNotificationPermission 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const logout = () => {
        const messageObject = {
            action: actionLogout,
            os: "web",  // 또는 "app"
            data: {
                // status: true
            }
        };

        addLog('logout 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const refresh = () => {
        const messageObject = {
            action: actionRefresh,
            os: "web",  // 또는 "app"
            data: {
                url: 'https://www.oqoq.ai/main/tab/order?tab=new'
            }
        };

        addLog('refresh 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const closeWeb = () => {
        const messageObject = {
            action: actionCloseWeb,
            os: "web",  // 또는 "app"
            data: {
                // status: true
            }
        };

        addLog('closeWeb 함수 호출');
        sendMessageToFlutter(messageObject);
    }

    const goDaum = () => {
        document.location.href = 'https://www.daum.net';
    }

    // React.js --> Flutter 로 함수 호출.
    const sendMessageToFlutter = (messageObject) => {

        // 객체를 JSON 문자열로 변환하여 Flutter로 전달
        if(window.flutterChannel) {
            window.flutterChannel.postMessage(JSON.stringify(messageObject));
        }
        else {
            addLog('Flutter 채널이 없습니다.');
        }
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">Flutter-Web Communication</h1>

            <p className="text-lg text-gray-600 bg-white p-4 rounded shadow mb-8">
                Message from flutter : {messageFromFlutter}
            </p>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={requestNotificationPermission}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : requestNotificationPermission
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={logout}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : logout
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={refresh}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : refresh
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={closeWeb}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : closeWeb
                </button>
            </div>

            <div style={ContainerStyle}>
                <button style={ButtonStyle}
                        onClick={goDaum}
                        className="px-6 py-3 text-white bg-blue-500 hover:bg-blue-700 rounded shadow"
                >
                    Send Message to Flutter : www.daum.net 으로 이동.
                </button>
            </div>

            <p className="mt-4 text-sm text-gray-500">
                * Press the button to send a message to the Flutter app.
            </p>

            <div>
                <h2>로그 출력:</h2>
                <div style={{whiteSpace: 'pre-wrap', border: '1px solid black', padding: '10px'}}>
                    {logs.map((log, index) => (
                        <div key={index}>
                            {log || <br/>} {/* 로그가 빈 문자열일 경우 <br />로 처리하여 빈 줄 출력 */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const ContainerStyle = {
    marginTop: '20px',
    marginBottom: '10px'
}

const ButtonStyle = {
    padding: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '40px',
    fontSize: '20px'
}

export default FlutterCallback;